"use client";

import { externalLinkCheck } from "@/utils/strapiTojs";
import { useInView } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
interface Props {
  backgroundColor?: string;
  smallText: string;
  bigText: string;
  feedBackCards: {
    description: string;
    name: string;
    designation: string;
    image: Image;
    socialIcon: Image;
    socialLink: string;
    video: Image;
    isTextTestimonial: boolean;
  }[];
}
export default function SectionTestimonials(props: Props) {
  const [activeVideo, setActiveVideo] = useState<number | null>();
  const {
    push
  } = useRouter();
  return <section className="section__testimonials" style={props.backgroundColor ? {
    backgroundColor: props.backgroundColor
  } : {}} data-sentry-component="SectionTestimonials" data-sentry-source-file="SectionTestimonials.tsx">
      <div className="section__testimonials-container section__container--small">
        <div className="section__testimonials-header">
          <p>{props.smallText}</p>
          <h2>{props.bigText}</h2>
        </div>
        <div className="section__testimonials-items">
          {props.feedBackCards.map((card, index) => {
          return card.isTextTestimonial ? <SectionTestimonialsItem key={index} {...card} /> : <SectionTestimonialsItemVideo key={index} {...card} isActive={activeVideo === index} onPlay={() => setActiveVideo(index)} onStop={() => setActiveVideo(null)} />;
        })}
        </div>
        <button className="section__testimonials-button" onClick={() => {
        push("/wall-of-love");
      }}>
          See our Wall of Love
        </button>
      </div>
    </section>;
}
function SectionTestimonialsItem(card: Props["feedBackCards"][number]) {
  const pathName = usePathname();
  const truncatedDescription = (description: string, minLength: number, maxLength: number) => {
    const trimmedDescription = description.replace(/\s+/g, ' ').trim();
    if (trimmedDescription.length <= minLength) {
      return trimmedDescription;
    } else if (trimmedDescription.length > maxLength) {
      return trimmedDescription.substring(0, maxLength) + "...";
    } else {
      return trimmedDescription.substring(0, minLength) + "...";
    }
  };
  return <Link target={externalLinkCheck(card.socialLink ?? "/")} href={card.socialLink ?? "/"} className="section__testimonials-item" onClick={() => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
        event_label: 'Click event on Testimonial',
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
      });
    }
  }} data-sentry-element="Link" data-sentry-component="SectionTestimonialsItem" data-sentry-source-file="SectionTestimonials.tsx">
      {card?.socialIcon?.src && card.socialLink && <div className="section__testimonials-item-platform-logo">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H10C25.0849 0 32.6274 0 37.3137 4.68629C42 9.37258 42 16.9151 42 32V42H32C16.9151 42 9.37258 42 4.68629 37.3137C0 32.6274 0 25.0849 0 10V0Z" fill="#002726" />
        </svg>

        <Image height={20} width={20} src={card.socialIcon.src} alt={card.socialIcon.alt ?? "Testimonials Platform Logo"} loading='lazy' />
      </div>}
      <blockquote className="section__testimonials-item-text">
        {/* {card.description.length <= 80 ? card.description.substring(0, 80) : card.description.length < 312 ? card.description.substring(0, 312) + "..." : card.description} */}
        {truncatedDescription(card.description, 80, 312)}
      </blockquote>
      <div className="section__testimonials-item-author">
        <div className="section__testimonials-item-author-img">
          <Image height={48} width={48} src={card.image.src} alt={card.image.alt ?? "Testimonials User"} loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionTestimonials.tsx" />
        </div>
        <div className="section__testimonials-item-author-text">
          <blockquote className="section__testimonials-item-author-name">
            <span>{card.name}</span>
          </blockquote>
          <blockquote className="section__testimonials-item-author-designation">
            <span>{card.designation}</span>
          </blockquote>
        </div>
      </div>
    </Link>;
}
function SectionTestimonialsItemVideo(card: Props["feedBackCards"][number] & {
  isActive: boolean;
  onPlay: () => void;
  onStop: () => void;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const pathName = usePathname();
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const {
    isActive,
    onPlay,
    onStop
  } = card;
  const sectionRef = useRef<HTMLDivElement>(null);
  // const sectionInView = useInView(sectionRef , {once: true});
  const isInView = useInView(sectionRef, {
    once: true
  });
  const handleBtnClick = () => {
    if (isActive) {
      onStop();
    } else {
      onPlay();
      // setVideoSrc(card.video.src);
    }
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
        event_label: 'Click event on Video Testimonial',
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
      });
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isActive) {
        videoElement.play();
      } else {
        videoElement.pause();
        // * if we want to restart the video each time
        // videoElement.currentTime = 0;
      }
    }
  }, [isInView, isActive]);
  useEffect(() => {
    const videoElement = videoRef.current;
    const handleVideoEnd = () => {
      if (!videoElement) return;
      videoElement.pause();
      videoElement.currentTime = 0;
      onStop();
    };
    videoElement?.addEventListener("ended", handleVideoEnd);
    return () => {
      videoElement?.removeEventListener("ended", handleVideoEnd);
    };
  }, [onStop]);
  return <div ref={sectionRef} className="section__testimonials-item section__testimonials-item--video" data-sentry-component="SectionTestimonialsItemVideo" data-sentry-source-file="SectionTestimonials.tsx">
      <span className="section__testimonials-item-overlay"></span>
      


      {isInView && <div className={`section__testimonials-video  ${isActive ? 'vid-btn' : ""}`}>
          <video ref={videoRef} preload="metadata">
            <source src={`${card.video.src}#t=0.001`} type="video/mp4" />
          </video>
          <button onClick={handleBtnClick} className={`section__testimonials-video-button  ${isActive ? 'd-hidden' : "d-block"}`}>
            {isActive ? <GSPauseIcon /> : <GSPlayIcon />}
          </button>
        </div>}

      <blockquote className="section__testimonials-item--video-footer">
        <p>{card.name}</p>
        <span>{card.designation}</span>
      </blockquote>
    </div>;
}
function GSPlayIcon() {
  return <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="GSPlayIcon" data-sentry-source-file="SectionTestimonials.tsx">
      <g filter="url(#filter0_b_5107_2311)" data-sentry-element="g" data-sentry-source-file="SectionTestimonials.tsx">
        <circle cx={21} cy={21} r={21} fill="white" data-sentry-element="circle" data-sentry-source-file="SectionTestimonials.tsx" />
      </g>
      <path d="M16 16.2631V24.7381C16 26.4738 17.7479 27.5631 19.1429 26.6952L22.5483 24.5787L25.9537 22.4533C27.3488 21.5854 27.3488 19.4158 25.9537 18.5479L22.5483 16.4225L19.1429 14.306C17.7479 13.4381 16 14.5185 16 16.2631Z" fill="#002726" data-sentry-element="path" data-sentry-source-file="SectionTestimonials.tsx" />
      <defs data-sentry-element="defs" data-sentry-source-file="SectionTestimonials.tsx">
        <filter id="filter0_b_5107_2311" x={-3} y={-3} width={48} height={48} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="SectionTestimonials.tsx">
          <feFlood floodOpacity={0} result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="SectionTestimonials.tsx" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" data-sentry-element="feGaussianBlur" data-sentry-source-file="SectionTestimonials.tsx" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5107_2311" data-sentry-element="feComposite" data-sentry-source-file="SectionTestimonials.tsx" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5107_2311" result="shape" data-sentry-element="feBlend" data-sentry-source-file="SectionTestimonials.tsx" />
        </filter>
      </defs>
    </svg>;
}
function GSPauseIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="white" data-sentry-element="svg" data-sentry-component="GSPauseIcon" data-sentry-source-file="SectionTestimonials.tsx">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM9 9V15H11V9H9ZM13 9V15H15V9H13Z" data-sentry-element="path" data-sentry-source-file="SectionTestimonials.tsx"></path>
    </svg>;
}