export function formatDate(dateString: string, timeZone: any, tempoptions?: any): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: timeZone.utc[0]
  };
  const options2: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: timeZone.utc[0]
  };
  const date = new Date(dateString).toLocaleString("en-IN", tempoptions ? tempoptions : options);
  const localTime = new Date(dateString);
  const formattedDate = date;
  const today = new Date();
  if (localTime.getDate() === today.getDate() && localTime.getMonth() === today.getMonth() && localTime.getFullYear() === today.getFullYear()) {
    return `Today, ${new Date(dateString).toLocaleString("en-IN", tempoptions ? tempoptions : options2)} ${tempoptions ? "" : timeZone.abbr}`;
  } else if (localTime.getDate() === today.getDate() + 1 && localTime.getMonth() === today.getMonth() && localTime.getFullYear() === today.getFullYear()) {
    return `Tomorrow, ${new Date(dateString).toLocaleString("en-IN", tempoptions ? tempoptions : options2)} ${tempoptions ? "" : timeZone.abbr}`;
  } else {
    return `${formattedDate} ${tempoptions ? "" : timeZone.abbr}`;
  }
}
export const formatDateNormal = (dateString: string) => {
  const date = new Date(dateString);
  const getDayString = (day: number) => {
    switch (day) {
      case 1:
        return 1 + "st";
      case 2:
        return 2 + "nd";
      case 3:
        return 3 + "rd";
      case 21:
        return 21 + "st";
      case 22:
        return 22 + "nd";
      case 23:
        return 23 + "rd";
      case 31:
        return 31 + "st";
      default:
        return day + "th";
    }
  };
  const monthString = (month: number) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return months[month];
  };
  return `${getDayString(date.getDate())} ${monthString(date.getMonth())} ${date.getFullYear()}`;
};