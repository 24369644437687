import { apiService, strapiApiService } from './apiService';
import { LEARNERS_ENDPOINTS, STRAPI_ENDPOINTS } from './endPoints';
import { Industry } from './types';

export const fetchDiscoveryData = async () => {
  try {
    const res = await apiService(
      `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getPrograms}`,
      true
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Error fetching learnerData:', error);
    return null; // Return null in case of an error
  }
};

export const fetchAllInstructors = async () => {
  const res = apiService(
    `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getInstructors}`,
    true
  );
  return (await res).json();
};

export const fetchMentors = async () => {
  const res = strapiApiService(
    `${STRAPI_ENDPOINTS.SERVER_URL}${STRAPI_ENDPOINTS.getMentorsData}`,
    true
  );
  return (await (await res).json()).data;
}

// export const fetchIndustryData = async (industryId: number) => {
//   const res = apiService(
//     `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getIndustryData(industryId)}`,
//     true
//   );
//   return (await res).json();
// };

// export const fetchSubIndustryData = async (subIndustryId: number) => {
//   const res = apiService(
//     `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getSubIndustryData(subIndustryId)}`,
//     true
//   );
//   return (await res).json();
// };

// export const fetchSubIndustryCount = async (industryId: number) => {
//   const res = apiService(
//     `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getSubIndustryCount(industryId)}`,
//     true
//   );
//   return (await res).json();
// };

export const fetchIndustriesData = async () => {
  const res = strapiApiService(
    `${STRAPI_ENDPOINTS.SERVER_URL}${STRAPI_ENDPOINTS.getIndustriesData}`,
    true
  );
  return (await (await res).json()).data as Industry[];
}

export const fetchPerks = async () => {
  const res = apiService(
    `${LEARNERS_ENDPOINTS.SERVER_URL}${LEARNERS_ENDPOINTS.getPerks}`,
    true
  );
  return (await res).json();
};
