export const imageLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  return `${
    src.startsWith('http') ? '' : process.env.NEXT_PUBLIC_APP_SERVER_URL
  }${src}?w=${width}&q=${quality || 75}`;
};

export const extractImage = (
  image:
    | {
        data?: {
          attributes?: {
            url?: string | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      }
    | null
    | undefined
): Image => {
  const img = image?.data?.attributes;
  return {
    src: img?.url?.startsWith('http')
      ? img?.url ?? ''
      : process.env.NEXT_PUBLIC_APP_SERVER_URL + (img?.url ?? ''),
    alt: img?.alternativeText ?? '',
  };
};

export const extractButtonProps = (
  button:
    | {
        Text?: string | null | undefined;
        Link?: string | null | undefined;
      }
    | null
    | undefined
) => {
  return { text: button?.Text ?? '', link: button?.Link ?? '' };
};

export const httpCheck = (link: string) => {
  if (link?.includes('http')) {
    return '_blank';
  } else {
    return '_self';
  }
};

export const formatNumber = (number: number) => {
  const formattedNumber = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);

  const formattedNumberLength = formattedNumber.length;

  return {
    number: parseFloat(formattedNumber.replace(/,/g, '')),
    shortHand:
      formattedNumberLength > 3
        ? formattedNumber.charAt(formattedNumberLength - 1)
        : '',
  };
};

export const externalLinkCheck = (link: string) => {
  if (link.includes('http')) {
    return '_blank';
  } else {
    return '_self';
  }
};
