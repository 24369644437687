"use client";

import useViewType from '@/utils/Hooks/useViewType';
import Image from 'next/image';
import React from 'react';
interface Props {
  title: string;
  logos: {
    image: Image;
  }[];
  backgroundColor?: string;
}
export default function SectionAlumni(props: Props) {
  const isMobile = useViewType();
  return <div style={{
    backgroundColor: isMobile ? props.backgroundColor : "transparent"
  }} data-sentry-component="SectionAlumni" data-sentry-source-file="SectionAlumni.tsx">
      <div className="section__alumni" style={{
      backgroundColor: isMobile ? props.backgroundColor : "transparent"
    }}>
        <div className="section__alumni-container section__container--small">
          <div className="section__alumni-header">
            <p>{props.title}</p>
          </div>
          <div className="section__alumni-cards">
            {props.logos.map((logo, index) => <div key={index} className="section__alumni-card">
                <Image src={logo.image.src} alt={logo.image.alt} width={100} height={100} loading='lazy' />
              </div>)}
          </div>
        </div>
      </div>
    </div>;
}