"use client";

interface SliderProps {
  slidesLength?: number;
  prevButtonClassName: string;
  nextButtonClassName: string;
}
function SliderArrows({
  slidesLength = 0,
  prevButtonClassName,
  nextButtonClassName
}: SliderProps) {
  return slidesLength > 1 ? <>
        <button className={`section__programs-arrow section__programs-arrow-prev ${prevButtonClassName}`}>
          <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" />
            <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button className={`section__programs-arrow section__programs-arrow-next ${nextButtonClassName}`}>
          <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" />
            <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </> : <>
        <button className={prevButtonClassName}></button>
        <button className={nextButtonClassName}></button>
      </>;
}
export default SliderArrows;