const transformStrings = (input:string) => {
    switch (input) {
      case 'RECORDED':
        return 'Recorded';
      case 'LIVE':
        return 'Live';
      case 'LIVE AND RECORDED':
        return 'Live and Recorded';
      default:
        return input;
    }
  };
  
  export default transformStrings;
  