"use client";

import { externalLinkCheck } from "@/utils/strapiTojs";
import { motion, useInView } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRef } from "react";
interface Props {
  caption: string;
  title: string;
  subTitle: string;
  buttonText: string;
  buttonLink: string;
  whyUsTitle: string;
  whyUs: {
    title: string;
    description: string;
    icon: Image;
  }[];
  brandCollabTitle: string;
  collaborationBrand: {
    image: Image;
  }[];
  varient?: number;
}
function SectionUpskill({
  varient = 1,
  ...props
}: Props) {
  const colors = ["#3686f7", "#02b50d", "#FF6347", "#FF4500"];
  const cardsRef = useRef(null);
  const cardsInView = useInView(cardsRef);
  const pathName = usePathname();
  return <section className={`section__upskill ${varient === 2 ? "section__upskill--2" : ""}`} id="up-skill" data-sentry-component="SectionUpskill" data-sentry-source-file="SectionUpskill.tsx">
      <div className="section__upskill-container section__container--small">
        <div className="section__upskill-header">
          <div className="section__upskill-header-text">
            <p>{props.caption}</p>
            <h2>{props.title}</h2>
          </div>
          <Link
        // target={externalLinkCheck(props.buttonLink && "https://growthschool.io/b2b")}
        href={props.buttonLink && "https://home.growthschool.io/b2b"} className="section__upskill-link" data-sentry-element="Link" data-sentry-source-file="SectionUpskill.tsx">
            {props.buttonText}
          </Link>
        </div>
        <p className="section__upskill-header-desc">{props.subTitle}</p>

        <div className="section__upskill-content">
          <div className="section__upskill-content-text">
            <h5>{props.whyUsTitle}</h5>
          </div>
          <div className="section__upskill-content-grid">
            {props.whyUs?.map((item, index) => <SectionUpskillItem key={index} {...item} />)}
          </div>
        </div>

        <div className="section__upskill-brands">
          <h5>{props.brandCollabTitle}</h5>
          <motion.div className="section__upskill-brands-grid" variants={{
          hidden: {
            opacity: 0
          },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1
            }
          }
        }} initial="hidden" animate={cardsInView ? "visible" : "hidden"} ref={cardsRef} data-sentry-element="unknown" data-sentry-source-file="SectionUpskill.tsx">
            {props.collaborationBrand?.map((brand, index) => <motion.div className="section__upskill-brands-grid-group" variants={{
            hidden: {
              opacity: 0
            },
            visible: {
              opacity: 1,
              transition: {
                type: "spring"
              }
            }
          }} key={index}>
                <div
            // style={{
            //   backgroundColor: `${colors[index]}1c`,
            //   border: `1px solid ${colors[index]}33`,
            // }}
            className="section__upskill-brands-grid-item">
                  <Image src={brand.image.src} alt={brand.image.alt || "brand"} width={100} height={100} loading='lazy' />
                  <p>Google</p>
                </div>
              </motion.div>)}
          </motion.div>
        </div>

        <Link
      // target={externalLinkCheck(props.buttonLink && "https://growthschool.io/b2b")}
      href={props.buttonLink && "https://home.growthschool.io/b2b"} className="section__upskill-link section__upskill-link-mobile" onClick={() => {
        if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
          window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
            event_label: 'Click event on Upskill the team',
            page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
          });
        }
      }} data-sentry-element="Link" data-sentry-source-file="SectionUpskill.tsx">
          {props.buttonText}
        </Link>
      </div>
    </section>;
}
export default SectionUpskill;
function SectionUpskillItem(props: Props["whyUs"][number]) {
  return <div className="section__upskill-content-grid-item" data-sentry-component="SectionUpskillItem" data-sentry-source-file="SectionUpskill.tsx">
      <div className="section__upskill-content-grid-item-img">
        <Image src={props.icon.src} alt={props.icon.alt} width={44} height={44} loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionUpskill.tsx" />
      </div>
      <div className="section__upskill-content-grid-item-text">
        <h6>{props.title}</h6>
        <p>{props.description}</p>
      </div>
    </div>;
}