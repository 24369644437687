let storageMechanism: any;
if (typeof window !== "undefined") {
  storageMechanism = window.localStorage;
}
export const setItem = (key: string, value: any) => {
  storageMechanism.setItem(key, value);
};
export const getItem = (key: string) => {
  if (storageMechanism) {
    const value = storageMechanism.getItem(key);
    if (value) {
      return value;
    }
    return null;
  }
};
export const removeItem = (key: string) => {
  storageMechanism.removeItem(key);
};
export const clearStorage = async () => {
  await storageMechanism.clear();
};