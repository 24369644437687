'use client';

import { externalLinkCheck } from '@/utils/strapiTojs';
import Image from 'next/image';
import Link from 'next/link';
import GSGrid from '../../../../public/images/new/GS-Grid.png';
import GSNSQLogo from '../../../../public/images/new/GS-NSQ-Logo.svg';
import GSTexture from '../../../../public/images/new/GS-Texture.png';
interface Props {
  title: string;
  quizLink: Button;
  pathName: string;
}
function SectionCtaCard(props: Props) {
  return <div className="section__programs-card-cta" data-sentry-component="SectionCtaCard" data-sentry-source-file="SectionCtaCard.tsx">
      <Link target={externalLinkCheck(props.quizLink.link ?? '/')} href={props.quizLink.link ?? '/'} style={{
      textDecoration: "none"
    }} onClick={() => {
      if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
        window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
          event_label: 'Click event on Next Step Quiz',
          page_path: `${process.env.NEXT_PUBLIC_APP_URL}${props.pathName}`
        });
      }
    }} data-sentry-element="Link" data-sentry-source-file="SectionCtaCard.tsx">
      <div className="section__programs-card-cta-container section__container--small">
        <Image src={GSTexture} alt="texture" className="section__programs-card-cta-texture" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionCtaCard.tsx" />
        <Image src={GSGrid} alt="grid" className="section__programs-card-cta-grid" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionCtaCard.tsx" />
        <div className="section__programs-card-cta-text">
          <h3>{props.title}</h3>
          <div className='nsw'>
            <span>{props.quizLink.text}</span>
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="SectionCtaCard.tsx">
              <path d="M15.0003 8.38209L7.37067 16.0117L5.33533 13.9763L8.90938 10.4023L0.895732 10.5134L0.739695 6.87847L9.18984 6.75684L5.2166 2.7836L7.30918 0.691017L15.0003 8.38209Z" fill="#33C375" data-sentry-element="path" data-sentry-source-file="SectionCtaCard.tsx" />
            </svg>
            </div>
        </div>
        <div className="section__programs-card-cta-img">
          <Image src={GSNSQLogo} width={66} height={66} alt="GSNSQLogo" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionCtaCard.tsx" />
        </div>
      </div>
      </Link>
    </div>;
}
export default SectionCtaCard;