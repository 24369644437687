"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation } from "swiper/modules";
import Image from "next/image";
import useViewType from "@/utils/Hooks/useViewType";
interface Props {
  smallText: string;
  bigText: string;
  successCards: {
    studentImage?: Image;
    imageURL: string;
    studentName: string;
    fromText?: string;
    fromCompany: string;
    toText?: string;
    toCompany: string;
  }[];
  backgroundColor?: string;
}
export default function SectionLearners(props: Props) {
  const isMobile = useViewType();
  return <div className="section__learners" style={{
    backgroundColor: isMobile ? props.backgroundColor : "transparent"
  }} data-sentry-component="SectionLearners" data-sentry-source-file="SectionLearners.tsx">
      <div className="section__learners-header section__container--small">
        <p>{props.smallText}</p>
      </div>
      <div className="section__learners-container">
        <SliderLearners {...props} data-sentry-element="SliderLearners" data-sentry-source-file="SectionLearners.tsx" />
      </div>
    </div>;
}
function SliderLearners(props: Props) {
  const swiperSettings = {
    spaceBetween: 30,
    breakpoints: {
      0: {
        spaceBetween: 10
      },
      768: {
        spaceBetween: 20
      },
      1024: {
        spaceBetween: 30
      }
    },
    navigation: {
      prevEl: ".section__learners-arrow-prev--Learners",
      nextEl: ".section__learners-arrow-next--Learners"
    }
  };
  return <div className="section__learners-slider-container" data-sentry-component="SliderLearners" data-sentry-source-file="SectionLearners.tsx">
      <div className="section__learners-slider-header section__container--small">
        <div className="section__learners-header">
          <h2 className="heading__secondary">{props.bigText}</h2>
        </div>
        <div className="section__learners-arrows">
          <button className="section__learners-arrow section__learners-arrow-prev section__learners-arrow-prev--Learners">
            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="SectionLearners.tsx">
              <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" data-sentry-element="path" data-sentry-source-file="SectionLearners.tsx" />
              <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SectionLearners.tsx" />
            </svg>
          </button>
          <button className="section__learners-arrow section__learners-arrow-next section__learners-arrow-next--Learners">
            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="SectionLearners.tsx">
              <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" data-sentry-element="path" data-sentry-source-file="SectionLearners.tsx" />
              <path d="M1.1377 14.1203L7.7377 7.50156L1.1377 0.882812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SectionLearners.tsx" />
            </svg>
          </button>
        </div>
      </div>
      <div className="section__learners-slider">
        <Swiper {...swiperSettings} className="section__learners-slider-swiper" modules={[Navigation, Mousewheel]} slidesPerView={"auto"} mousewheel={{
        eventsTarget: ".section__learners-slider-swiper",
        forceToAxis: true
        // sensitivity: 10,
        // thresholdDelta: 10,
      }} data-sentry-element="Swiper" data-sentry-source-file="SectionLearners.tsx">
          {props.successCards.map((item, index) => <SwiperSlide key={index}>
              <SlideLearners {...item} />
            </SwiperSlide>)}
        </Swiper>

        <div className="section__learners-slider-swiper--mobile">
          {props.successCards.map((item, index) => <SwiperSlide key={index}>
              <SlideLearners {...item} />
            </SwiperSlide>)}
        </div>
      </div>
    </div>;
}
function SlideLearners(props: Props["successCards"][number]) {
  return <div className="section__learners-slider-slide" data-sentry-component="SlideLearners" data-sentry-source-file="SectionLearners.tsx">
      <div className="section__learners-slider-slide-header">
        <div className="section__learners-slider-slide-img">
          <Image src={props.imageURL} alt={props.studentImage?.alt ?? "default"} height="48" width="48" loading="lazy" className="section__learners-slider-slide-img" data-sentry-element="Image" data-sentry-source-file="SectionLearners.tsx" />
        </div>
        <p>{props.studentName}</p>
      </div>
      <div className="section__learners-slider-slide-cards">
        <div className="section__learners-slider-slide-card">
          {props.fromText}
          <span>{props.fromCompany}</span>
        </div>
        <div className="section__learners-slider-slide-card-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" fill="none" data-sentry-element="svg" data-sentry-source-file="SectionLearners.tsx">
            <path d="M8.5 10L12.5 14L16.5 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SectionLearners.tsx" />
          </svg>
        </div>
        <div className="section__learners-slider-slide-card">
          {props.toText}
          <span>{props.toCompany}</span>
        </div>
      </div>
    </div>;
}