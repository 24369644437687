import { motion } from "framer-motion";
export default function Counter({
  targetValue
}: {
  targetValue: number;
}) {
  // Convert to fixed 1 decimal place and split into whole and decimal parts
  const [wholePart, decimalPart] = targetValue.toFixed(1).split(".");
  const targetNumber = wholePart.split("").concat([".", ...decimalPart.split("")]);
  const heightPerNumber = 30;
  return <div className="section__counter" data-sentry-component="Counter" data-sentry-source-file="Counter.tsx">
      {targetNumber.map((char, index) => <motion.div key={index} className="section__counter-digit" initial={{
      y: 0
    }} style={{
      height: heightPerNumber
    }}>
          {char === "." ? <div className="flex items-center justify-center w-2 h-8 text-2xl font-bold">
              .
            </div> : <motion.div className="section__counter-column" animate="visible" initial="hidden" variants={{
        visible: {
          y: -heightPerNumber * (parseInt(char) + 20),
          transition: {
            duration: 5,
            ease: "circInOut",
            delay: 0.05 * index
          }
        },
        hidden: {
          y: 0
        }
      }}>
              {new Array(30).fill(0).map((_, idx) => <div key={idx} style={{
          height: heightPerNumber
        }} className="section__counter-number">
                  {idx % 10}
                </div>)}
            </motion.div>}
        </motion.div>)}
    </div>;
}